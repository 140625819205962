
function App() {
  return (
    <div>
      Sunil Tailors - Site is under construction
    </div>
  );
}

export default App;
